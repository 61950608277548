html , body{
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}

.App {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.MessageText {
    margin: 0.1em 0.5em;
    padding: 0.5em;
    border-radius: 0.5em;
    flex-shrink: 0;
}

.MessageDate {
    font-size: 0.8em;
    color: gray;
    margin-left: 1em;
    margin-right: 1em;
    align-self: flex-end;
}

.MessageSender {
    font-weight: bold;
    margin-top: 0.75em;
    margin-left: 1em;
    margin-right: 1em;
}

.Message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 80%;
}

.OptionSelector {
    text-align: center;
    margin: 0.5em;
    padding: 0.5em;
    background-color: #ffffff;
    border-radius: 0.5em;
    align-items: center;
}

.OptionSelector:hover {
    cursor: pointer;
}

.ConversationHeader {
    width: 600px;
    height: 100px;
    align-self: center;
    padding: 1em;
    margin-top: 5em;
    background-color: #15a9bf;
    color: white;
    display: flex;
    align-items: center;
}

.ConversationManager {
    width: 600px;
    height: 80%;
    background: #ece9e9;
    margin: 0 auto auto auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em;
    overflow: auto;
}

.ConvoTopHalf {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.NotifCount {
    background-color: #d94343;
    color: white;
    font-weight: bolder;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    margin:auto;
    text-align: center;
    line-height: 1.55em;
    font-size: 25px;
}

.ConvoBottomHalf {
    width: 100%;
}